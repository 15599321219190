import React from "react";
import ResultLine from "./ResultLine";
import ShareButton from "./ShareButton";
import ServerVariable from "./ServerVariable";

interface Props {
  lines: Line[];
  outputUrl: string;
  formRef: React.RefObject<HTMLFormElement | null>;
  requestUrl: string;
  document: string;
  serverVariables: ServerVariable[];
}

const Results = ({
  lines,
  outputUrl,
  formRef,
  requestUrl,
  document,
  serverVariables,
}: Props) => {
  return (
    <section className="results-container">
      {outputUrl !== "" && (
        <div className="form-group output-url">
          <label htmlFor="output-url" className="output-url-label">
            Output url
          </label>
          <div className="input-container">
            <input
              aria-label="Request url"
              type="url"
              name="output-url"
              placeholder=""
              value={outputUrl}
              required
              autoComplete="off"
              autoCapitalize="off"
              spellCheck={false}
              className="result__url"
              readOnly
            />
          </div>
        </div>
      )}
      <div>
        <div className="debug-share-container">
          <h4 className="debug-title">Debugging info</h4>
          <div className="share-container">
            <p>Need to share?</p>
            <ShareButton
              form={formRef}
              requestUrl={requestUrl}
              document={document}
              serverVariables={serverVariables}
            />
          </div>
        </div>
        <div className="results">
          {lines.map((line, index) => {
            return (
              <ResultLine line={line} key={index} lineNumber={index + 1} />
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Results;
