import React, { useState, useEffect, useRef } from "react";
import useHtaccessApi from "./hooks/useHtaccessApi";
import ServerVariable from "./ServerVariable";

interface Props {
  setResult: (result: Result) => void;
  lines: object[];
  document: string;
  setDocument: (document: string) => void;
  requestUrl: string;
  setRequestUrl: (requestUrl: string) => void;
  serverVariables: ServerVariable[];
  setServerVariables: (serverVariables: ServerVariable[]) => void;
  formRef: React.Ref<HTMLFormElement | null>;
}

const Form = ({
  setResult,
  document,
  setDocument,
  requestUrl,
  setRequestUrl,
  serverVariables,
  setServerVariables,
  formRef,
}: Props) => {
  const [requestUrlError, setRequestUrlError] = useState("");
  const doApiCall = useHtaccessApi();
  const hasServerVariable = serverVariables.some(
    (serverVariable) =>
      serverVariable.key !== "" || serverVariable.value !== "",
  );
  const [showAdvanced, setShowAdvanced] = useState(hasServerVariable);
  const [error, setError] = useState("");

  useEffect(() => setShowAdvanced(hasServerVariable), [hasServerVariable]);

  const changeRequestUrl = (event: React.FormEvent<HTMLInputElement>) => {
    setRequestUrl(event.currentTarget.value);
    setRequestUrlError("");
  };

  const changeDocument = (event: React.FormEvent<HTMLTextAreaElement>) => {
    setDocument(event.currentTarget.value);
  };

  const addServerVariable = () => {
    serverVariables.push({ key: "", value: "" });
    setServerVariables([...serverVariables]);
  };

  const changeServerVariable = (
    index: number,
    serverVariable: ServerVariable,
  ) => {
    serverVariables[index] = serverVariable;
    setServerVariables([...serverVariables]);
  };

  const removeServerVariable = (index: number) => {
    serverVariables.splice(index, 1);
    setServerVariables([...serverVariables]);
  };

  const submit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setError("");

    doApiCall({
      url: requestUrl,
      htaccess: document,
      serverVariables: serverVariables
        .filter((serverVariable: ServerVariable) => serverVariable.key !== "")
        .reduce((carry: ServerVariables, serverVariable: ServerVariable) => {
          carry[serverVariable.key] = serverVariable.value;
          return carry;
        }, {}),
    }).then((response) => {
      if (response.errors) {
        response.errors.forEach((error) => {
          if (error.field === "url") {
            setRequestUrlError(error.message);
          }
        });
      } else if (response.message) {
        setError(response.message);
      } else if (response.exception) {
        setError(
          "We could not handle this request, is your htaccess file valid?",
        );
      } else {
        setResult(response);
      }
    });
  };

  const toggleAdvanced = () => {
    setShowAdvanced((prevShowAdvanced) => !prevShowAdvanced);
  };

  return (
    <form
      ref={formRef}
      onSubmit={submit}
      id="htaccess-tester-form"
      className="tester-form"
      method="POST"
      action="/"
    >
      {error && (
        <div className="error">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            width="20"
          >
            <g>
              <g>
                <path
                  d="M501.362,383.95L320.497,51.474c-29.059-48.921-99.896-48.986-128.994,0L10.647,383.95
                  c-29.706,49.989,6.259,113.291,64.482,113.291h361.736C495.039,497.241,531.068,433.99,501.362,383.95z M256,437.241
                  c-16.538,0-30-13.462-30-30c0-16.538,13.462-30,30-30c16.538,0,30,13.462,30,30C286,423.779,272.538,437.241,256,437.241z
                  M286,317.241c0,16.538-13.462,30-30,30c-16.538,0-30-13.462-30-30v-150c0-16.538,13.462-30,30-30c16.538,0,30,13.462,30,30
                  V317.241z"
                />
              </g>
            </g>
          </svg>
          {error}
        </div>
      )}
      <div className="form-group">
        <label htmlFor="url">
          Fill in the url that you're applying the rules to.
        </label>
        <div className="input-container">
          <input
            aria-label="Request url"
            type="url"
            className={`input--monospace ${requestUrlError && " has-error"}`}
            name="url"
            placeholder="https://madewithlove.com"
            value={requestUrl}
            onChange={changeRequestUrl}
            required
            autoComplete="off"
            autoCapitalize="off"
            spellCheck={false}
          />
        </div>
      </div>
      {requestUrlError && (
        <p className="form-error">
          <span className="glyphicon glyphicon-exclamation-sign" />{" "}
          {requestUrlError}
        </p>
      )}
      <div className="form-group">
        <label htmlFor="htaccess">
          Paste your .htaccess rules into the form
        </label>
        <div className="input-container">
          <textarea
            wrap="off"
            aria-label="Htaccess file"
            name="htaccess"
            className="textarea--monospace"
            rows={14}
            onChange={changeDocument}
            value={document}
            placeholder="Your .htaccess file"
            required
            autoComplete="off"
            autoCapitalize="off"
            spellCheck={false}
          />
        </div>
      </div>

      <div
        style={{ display: showAdvanced ? "block" : "none" }}
        id="advanced-fields"
        className="advanced-fields-container"
      >
        <div className="server-variable">
          <label>Option name</label>
          <label>Value</label>
        </div>
        {serverVariables.map((serverVariable: ServerVariable, index) => {
          return (
            <ServerVariable
              key={"server-variable-" + index}
              serverVariable={serverVariable}
              index={index}
              changeServerVariable={(serverVariable: ServerVariable) =>
                changeServerVariable(index, serverVariable)
              }
              removeServerVariable={() => removeServerVariable(index)}
            />
          );
        })}
        <button
          type="button"
          name="add_server_variable"
          className="button"
          onClick={addServerVariable}
        >
          Add server variable
        </button>
      </div>
      <div>
        <a
          onClick={toggleAdvanced}
          className={`advanced-fields-toggle ${
            showAdvanced ? "expanded" : "collapsed"
          }`}
          aria-expanded={showAdvanced ? "true" : "false"}
          aria-controls="advanced-fields"
        >
          Advanced
          <span className="glyphicon glyphicon-chevron-right" />
        </a>
        <button type="submit" name="action" className="button">
          Test
        </button>{" "}
      </div>
    </form>
  );
};

export default Form;
